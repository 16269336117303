<!-- 首页 -->
<template>
  <div class="frame">
    <div class="content">
      <img src="../assets/home_logo.svg" alt="" class="biglogo" />
      <div class="center">
        <img src="../assets/code1.jpg" alt="" class="server" />
        <div class="tip">长按二维码识别关注</div>
      </div>
      <div class="join" @click="$router.push('/welcome')">参与活动</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.content {
  .biglogo {
    width: 60%;
    margin-top: 15%;
  }
  .center {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 8%;
  }
  .server,
  .public {
    width: 35%;
  }
  img {
    margin: 0 auto;
    display: block;
  }
  .tip {
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
  }
  .join {
    width: 40%;
    height: 44px;
    margin: 36px auto 0;
    text-align: center;
    color: #1e767b;
    background: linear-gradient(#fff, #b0eef1);
    line-height: 44px;

    &:active {
      transform: scale(0.9);
    }
  }
}
</style>
