<template>
  <header class="header" :style="{ top }">
    <router-link to="/">
      <img
        :style="{ visibility: logoShow ? 'visible' : 'hidden' }"
        src="../assets/home_logo.svg"
        alt="大运logo"
        class="logo"
      />
    </router-link>
    <div class="right" v-show="rightShow">
      <span class="count" v-show="count != 0">浏览{{ count }}秒</span>
      <img
        src="../assets/back.png"
        class="back"
        v-show="count == 0"
        @click="$emit('routerGo')"
      />
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      count: 8,
      timer: null,
    };
  },
  props: {
    top: {
      type: String,
      default: "5vh",
    },
    logoShow: {
      type: Boolean,
      default: true,
    },
    isCount: {
      type: Boolean,
      default: false,
    },
    rightShow: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (!this.isCount) {
      this.count = 0;
      return;
    }
    this.timer = setInterval(() => {
      if (this.count == 0) {
        clearInterval(this.timer);
        return;
      }
      this.count--;
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 999;
  padding: 0 5%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: auto;
    height: 25px;
  }
  .back {
    width: auto;
    height: 20px;
  }
  .count {
    color: #fff;
    font-size: 14px;
  }
}
</style>