import request from "../request";

const api = {
  // 微信静默登录
  wxLogin(params) {
    return request({
      url: "web/wxlogin-user/front/notify/code",
      method: "get",
      params
    });
  },
  // 发送验证码
  sendSms(params) {
    return request({
      url: "web/record-form/sendMessage",
      method: "post",
      params
    });
  },
  // 新增盲盒列表
  addRecord(data) {
    return request({
      url: "web/record-form/system",
      method: "post",
      data
    });
  },
  // 生成二维码
  createCode(params) {
    return request({
      url: "web/writeoff-record/qrCode",
      method: "get",
      params,
      responseType: "blob"
    });
  },
  // 获取签名参数
  getSignParams(params) {
    return request({
      url: "web/wxlogin-user/system/getSign",
      method: "get",
      params
    });
  },
  // 添加合影记录
  addPhoto(data) {
    return request({
      url: "web/group-photo-record/system",
      method: "post",
      data
    });
  },
  // 核销
  startWriteOff(params) {
    return request({
      url: "web/writeoff-record/writeoffData",
      method: "post",
      params
    });
  },
  // 查询当前用户是否已填写表单
  validFormId() {
    return request({
      url: "web/record-form/verifyFrom",
      method: "get"
    });
  },
  // 上传serverId获得图片
  uploadServerId(params) {
    return request({
      url: "web/group-photo-record/getLicensePlate",
      method: "get",
      params
    });
  },
  // 获取礼品列表
  getGiftList(params) {
    return request({
      url: "web/gift-management/front/ListGiftManagement",
      method: "get",
      params
    });
  },
  // 开始抽奖
  startRaffle() {
    return request({
      url: "web/record-form/front/raffle",
      method: "post"
    });
  }
};

export default {
  ...api
};
