<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted(){
    sessionStorage.setItem("firstPath",this.$route.fullPath);
  }
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}
#app {
  color: #333;
}
</style>
