<!--  -->
<template>
  <div class="frame">
    <van-popup v-model="typeShow" position="bottom">
      <van-picker
        title="车型"
        show-toolbar
        :columns="types"
        @confirm="typeConfirm"
        @cancel="typeCancel"
      />
    </van-popup>
    <van-popup v-model="areaShow" position="bottom">
      <van-picker
        title="车型"
        show-toolbar
        value-key="ext_name"
        :columns="area"
        @confirm="areaConfirm"
        @cancel="areaCancel"
      />
    </van-popup>
    <top @routerGo="$router.back()"></top>
    <div class="content">
      <div class="form" v-if="submitStep == 1">
        <van-field
          v-model="params.messageName"
          placeholder="请输入您的姓名"
          input-align="center"
        />
        <van-field
          v-model="params.mobile"
          type="digit"
          label="+86"
          label-width="2em"
          placeholder="请输入您的手机号码"
          input-align="center"
        />
        <van-field
          v-model="params.code"
          type="digit"
          center
          clearable
          placeholder="请输入验证码"
          input-align="center"
          maxlength="6"
        >
          <template #button>
            <van-button
              size="mini"
              type="primary"
              :disabled="sendingCount != 0"
              @click="sendCode"
              >{{
                sendingCount == 0 ? "发送验证码" : sendingCount + "s后重发"
              }}</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="params.intendedVehicleModel"
          placeholder="请输入您的意向车型"
          input-align="center"
          readonly
          @click="typeShow = true"
        />
        <van-field
          v-model="params.city"
          placeholder="请选择您所在的城市"
          input-align="center"
          readonly
          @click="areaShow = true"
        />
        <van-field
          v-model="params.carPurchaseBudget"
          type="number"
          center
          clearable
          maxlength="10"
          placeholder="请输入您的购车预算"
          input-align="center"
        >
          <template #button>
            <span>万元</span>
          </template>
        </van-field>
      </div>
      <div class="tip-box" v-else-if="submitStep == 2">
        <div class="title">温馨提示</div>
        <div class="sub">您当前尚未完成其他板块的探索</div>
        <div class="tip">需要探索完<span>所有板块</span>才能获得二维码哦</div>
      </div>
      <div class="code-box" v-else>
        <div class="tip">
          您已获得 <span>{{ myPrize }}</span>
        </div>
        <div class="sub">请在活动当天前往工作人员处领取~</div>
        <div class="code-type">
          <img :src="codeUrl" alt="" class="code" />
          <div class="code-mask" v-show="isVerification">
            <span>已核销</span>
          </div>
        </div>
      </div>
    </div>
    <div class="submit" @click="submit" v-show="submitStep == 1">提交</div>
  </div>
</template>

<script>
import top from "../components/top.vue";
import areaList from "../plugins/city.json";

export default {
  data() {
    return {
      submitStep: 1, // 判断当前用户提交状态——1未提交 2已提交但未浏览所有板块 3已提交且浏览但未抽奖 4已抽奖展示二维码
      isVerification: false, // 若用户已获得二维码，判断用户当前二维码是否已被核销
      codeUrl: "",
      params: {
        messageName: "", // 留言姓名
        mobile: "", // 手机号码
        code: "", // 验证码
        intendedVehicleModel: "", // 意向车型
        carPurchaseBudget: "", // 购车预算
        city: "", // 所在城市
      },
      validRules: {
        messageName: { required: true, message: "请输入留言姓名" },
        mobile: { required: true, message: "请输入手机号码" },
        code: { required: true, message: "请输入验证码" },
        intendedVehicleModel: { required: true, message: "请选择意向车型" },
        city: { required: true, message: "请选择所在城市" },
        carPurchaseBudget: { required: true, message: "请输入购车预算" },
      },
      typeShow: false,
      types: ["远志M1", "悦虎"],
      areaShow: false,
      area: areaList,
      sendingCount: 0, // 发送验证码倒计时
      timer: null,
      confirmLoading: false,
      myPrize: "",
    };
  },
  components: { top },
  created() {
    if (!this.is_weixin()) return;
    const token = localStorage.getItem("dy_token");
    if (!token) {
      this.$router.push("/welcome");
      return;
    }
    this.validSubmit();
  },
  mounted() {},
  methods: {
    getRaffle() {
      this.$api.startRaffle().then((res) => {
        if (res.code == 0) {
          this.submitStep = 3;
          this.validSubmit();
        }
      });
    },
    // 检测当前用户是否已查看过其他三个分支页面
    validGlance() {
      const type = localStorage.getItem("page_type") || 0;
      const photo = localStorage.getItem("page_photo") || 0;
      const about = localStorage.getItem("page_about") || 0;
      const sum = Number(type) + Number(photo) + Number(about);
      if (sum != 3) {
        this.$dialog.alert({
          title: "温馨提示",
          message: "需要探索完所有板块才能获得二维码哦~",
        });
        this.submitStep = 2;
      }
      return sum == 3;
    },
    // 检验当前用户是否已提交过表单
    async validSubmit() {
      let loading = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
      });
      let res = await this.$api.validFormId();
      loading.clear();
      if (res.code != 0) return;
      // 存在表单id，
      if (res.data.id != null) {
        // 判断是否完成板块探索
        if (!this.validGlance()) return;
        // 判断是否完成抽奖
        if (res.data.giftId) {
          this.submitStep = 3;
          this.myPrize = res.data.giftName;
          this.codeCreated(res.data.id);
        } else {
          // 未抽奖则直接抽奖
          this.getRaffle();
        }

        this.isVerification = res.data.checkFlag; // 当前表单是否已核销
        if (!res.data.checkFlag) {
          // 若未核销，开始轮询核销状态
          this.turnValidSubmit();
        }
      }
    },
    // 轮询查看是否已核销
    turnValidSubmit() {
      // 5s一轮询
      setTimeout(async () => {
        let res = await this.$api.validFormId();
        if (res.code != 0) return;
        if (res.data.id != null) {
          if (res.data.checkFlag) {
            this.isVerification = res.data.checkFlag;
          } else {
            this.turnValidSubmit();
          }
        }
      }, 5000);
    },
    is_weixin() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.match(/MicroMessenger/i) == "micromessenger";
    },
    async codeCreated(id) {
      let res = await this.$api.createCode({ id });
      const url = window.URL.createObjectURL(res);
      this.codeUrl = url;
    },
    typeConfirm(value) {
      this.params.intendedVehicleModel = value;
      this.typeShow = false;
    },
    typeCancel() {
      this.typeShow = false;
    },
    areaConfirm(value) {
      this.params.city = value.length > 0 ? value.join("/") : "";
      this.areaShow = false;
    },
    areaCancel() {
      this.areaShow = false;
    },
    // 发送短信
    async sendCode() {
      const { mobile } = this.params;
      if (!mobile) {
        this.$toast.fail("请输入手机号码");
        return;
      }
      let res = await this.$api.sendSms({ tel: mobile });
      if (res.code != 0) return;
      this.$toast.success("发送成功!");
      // 倒计时
      this.sendingCount = 60;
      this.timer = setInterval(() => {
        this.sendingCount--;
        if (this.sendingCount == 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    submit() {
      // 提交验证
      for (const key in this.validRules) {
        const ele = this.validRules[key];
        if (ele.required && !this.params[key]) {
          this.$toast.fail(ele.message);
          return;
        }
      }
      // 验证成功
      const loading = this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      this.$api
        .addRecord(this.params)
        .then((res) => {
          if (res.code != 0) return;
          this.$toast("提交成功!");

          setTimeout(() => {
            Object.assign(this.$data.params, this.$options.data().params);
            this.validSubmit();
          }, 100);
        })
        .finally(() => {
          loading.clear();
        });
    },
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang='scss' scoped>
.content {
  margin: 120px 0 20px;
  background: url("../assets/kuang.png") no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  color: #fff;
  padding: 70px 30px 50px;
  box-sizing: border-box;
  text-align: center;

  ::v-deep .van-cell {
    padding: 6px 16px;
  }
}

.submit {
  width: 85%;
  height: 40px;
  line-height: 40px;
  background: linear-gradient(#fff, #b0eef1);
  margin: 0 auto 10px;
  color: #388584;
  letter-spacing: 10px;
  font-size: 18px;
  text-align: center;

  &:active {
    transform: scale(0.9);
  }
}
.form {
  width: 70%;
  margin: 0 auto;
}
.van-field {
  margin-bottom: 20px;
}

.tip-box {
  .title {
    font-size: 22px;
    margin-bottom: 5%;
  }
  .sub {
    margin-bottom: 5px;
  }
  .tip span {
    color: #b0eef1;
  }
}

.wheel-box {
  .tip {
    margin-bottom: 1%;
  }
  .sub {
    margin-bottom: 3%;
    font-size: 14px;
    color: red;
  }
  .wheel {
    width: fit-content;
    margin: 0 auto;
  }
}

.code-box {
  width: 70%;
  margin: 0 auto;

  .tip {
    margin-bottom: 2%;

    span {
      font-size: 20px;
      font-weight: bold;
      color: #b0eef1;
    }
  }

  .sub {
    font-size: 14px;
    margin-bottom: 10%;
  }

  .code-type {
    position: relative;
  }

  .code {
    width: 100%;
  }
  .code-mask {
    width: 100%;
    height: 100%;
    font-size: 20px;
    letter-spacing: 5px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
</style>
