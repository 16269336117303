<!--  -->
<template>
  <div class="frame">
    <top @routerGo="$router.push('/')"></top>
    <div class="content">
      <img src="../assets/welcome.png" alt="welcome" class="logo" />
      <div class="title">欢迎莅临大运新能源展台</div>
      <div class="in">
        <p>
          <img src="../assets/scan.png" alt="" class="icon" />
          <span>前台扫码赠扫码礼</span>
        </p>
        <p>
          <img src="../assets/photo.png" alt="" class="icon" />
          <span>与大运新能源合影打卡分享</span>
        </p>
        <p>
          <img src="../assets/question.png" alt="" class="icon" />
          <span>了解大运新能源</span>
        </p>
        <p>有机会获取大运新能源精美礼品哦</p>
        <div class="start" @click="$router.push('/homePage')">开始体验</div>
      </div>
    </div>
  </div>
</template>

<script>
import top from "../components/top.vue";
export default {
  components: { top },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.content {
  width: 90%;
  margin: 145px auto 0;
  background-image: url("../assets/kuang.png");
  background-size: 100% 100%;
  padding: 40px 20px 50px;
  text-align: center;
  color: #fff;

  .logo {
    width: 78px;
    height: auto;
  }
  .title {
    margin-top: 7px;
    font-size: 18px;
    line-height: 45px;
  }
  .in {
    text-align: left;
    padding: 0 12%;
    margin-top: 7px;

    p {
      font-size: 18px;
      line-height: 45px;
      display: flex;
      align-items: center;

      .icon {
        width: 26px;
        height: 26px;
      }
      span {
        margin-left: 10px;
      }
    }
    .start {
      width: 50%;
      height: 38px;
      line-height: 38px;
      margin: 36px auto 0;
      color: #1e767b;
      border: 3px solid;
      border-image: linear-gradient(0deg, #1e767b, #b0eef1) 1;
      text-align: center;
      background-color: #f8f8f8;
      user-select: none;
      cursor: pointer;

      &:active {
        background-color: #dedede;
        transform: scale(0.9);
      }
    }
  }
}
</style>
