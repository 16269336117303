<!--  -->
<template>
  <div class="frame">
    <top :isCount="true" @routerGo="$router.back()"></top>
    <div class="content">
      <div class="tabs">
        <div
          :class="{ tab: true, active: tabActive == '远志M1' }"
          @click="setTab('远志M1')"
        >
          远志M1
        </div>
        <div
          :class="{ tab: true, active: tabActive == '悦虎' }"
          @click="setTab('悦虎')"
        >
          悦虎
        </div>
      </div>
      <div id="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in swipeArr" :key="item">
            <img :src="item" class="slide" />
          </div>
        </div>
      </div>
      <div class="tips"></div>
    </div>
  </div>
</template>

<script>
const swipeTypes = {
  远志M1: [URL + "m1-1.png", URL + "m1-2.png", URL + "m1-3.png"],
  悦虎: [URL + "yuehu-1.png", URL + "yuehu-2.png", URL + "yuehu-3.png"],
};
import top from "../components/top.vue";
import { URL } from "../util/config";
export default {
  components: { top },
  data() {
    return {
      tabActive: "远志M1",
      swipe: null,
      swipeArr: [],
    };
  },
  created() {
    localStorage.setItem("page_type", 1);
  },
  mounted() {
    this.swipeArr = swipeTypes["远志M1"];
    this.initSwipe();
  },
  methods: {
    setTab(type) {
      this.tabActive = type;
      this.swipeArr = swipeTypes[type];
      this.swipe.slideToLoop(0);
    },
    initSwipe() {
      this.swipe = new window.Swiper("#swiper", {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.content {
  width: 90vw;
  margin: 0 auto;
  padding-top: 25%;

  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 17px;

    .tab {
      width: 100px;
      height: 16px;
      line-height: 16px;
      background: #b0eef1;
      padding: 8px 0;
      color: #1e767b;
      margin-right: 16px;
      text-align: center;
      border: 2px solid #b0eef1;
    }
    .active {
      color: #fff;
      background-color: rgba($color: #b0eef1, $alpha: 0.6);
      border-image: linear-gradient(-18deg, #1e767b, #b0eef1) 6 6;
    }
  }
  #swiper {
    width: 100%;
    overflow: hidden;
  }
  .slide {
    width: 100%;
    height: auto;
  }
}
</style>
