<!--  -->
<template>
  <div class="frame">
    <top @routerGo="$router.back()"></top>
    <div class="content">
      <div class="box" @click="openFirming">
        <img
          v-if="params.photoUrl"
          class="photo"
          :src="URL + params.photoUrl"
          alt=""
        />
        <van-empty
          v-else
          class="custom-image"
          :image="require('../assets/firming.png')"
          description="轻触拍照，记录美好"
        />
      </div>
      <div class="btns">
        <div class="btn change" v-show="params.photoUrl" @click="openFirming">
          更换
        </div>
        <div class="btn submit" @click="submit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import top from "../components/top.vue";
import { URL } from "../util/config";
import wx from "weixin-js-sdk";
export default {
  components: { top },
  data() {
    return {
      URL,
      params: {
        photoUrl: "",
      },
      hasPower: true, // 权限
      appId: "wx6da86b8072154b28",
    };
  },
  created() {
    localStorage.setItem("page_photo", 1);
  },
  mounted() {
    if (!this.is_weixin()) return;
    const token = localStorage.getItem("dy_token");
    if (!token) {
      this.$router.push("/welcome");
      return;
    }
    this.initWeiXin();
  },
  methods: {
    is_weixin() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.match(/MicroMessenger/i) == "micromessenger";
    },
    is_Android() {
      const u = navigator.userAgent;
      return u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
    },
    async initWeiXin() {
      let loading = this.$toast.loading({
        message: "请稍等",
        duration: 0,
        forbidClick: true,
        overlay: true,
      });
      let that = this;
      /**
       * android:在哪个页面哪个url就是realUrl
       * IOS兼容:IOS默认进入浏览器的第一个url为realUrl
       */
      const path = sessionStorage.getItem("firstPath");
      const pathName = path.split("/")[1];
      let res = await this.$api.getSignParams({
        path: this.is_Android() ? "photo" : pathName,
      });
      if (res.code == 0) {
        const { timestamp, noncestr, signature } = res.data;
        wx.config({
          debug: false,
          appId: this.appId,
          timestamp,
          nonceStr: noncestr,
          signature,
          jsApiList: ["chooseImage", "uploadImage"],
        });
        wx.ready(function () {
          wx.checkJsApi({
            jsApiList: ["chooseImage", "uploadImage"],
            success: function (res) {
              loading.clear();
              if (!res.checkResult.chooseImage) {
                that.$toast(res.checkResult.errMsg);
                that.hasPower = false;
              }
            },
          });
        });
        wx.error(function (res) {
          console.log(res);
          loading.clear();
          that.$toast.fail("出现了意外");
          that.hasPower = false;
        });
      }
    },
    // 打开拍摄事件
    openFirming() {
      if (!this.hasPower) {
        this.$toast("调用摄像头失败，请刷新");
        return;
      }
      wx.chooseImage({
        count: 1, // 默认9
        success: (res) => {
          let loading = this.$toast.loading({
            message: "请稍等",
            duration: 0,
            forbidClick: true,
            overlay: true,
          });
          wx.uploadImage({
            localId: res.localIds[0],
            isShowProgressTips: 1, // 是否显示上传进度
            success: async (uploadRes) => {
              let serverId = uploadRes.serverId;
              let apiRes = await this.$api.uploadServerId({
                mediaId: serverId,
              });
              this.params.photoUrl = apiRes.fileUrl;
              loading.clear();
            },
          });
        },
      });
    },
    async submit() {
      if (!this.params.photoUrl) {
        this.$toast.fail("请选择照片");
        return;
      }
      let loading = this.$toast.loading({
        message: "提交中",
        duration: 0,
        forbidClick: true,
        overlay: true,
      });
      try {
        let res = await this.$api.addPhoto(this.params);
        loading.clear();
        if (res.code == 0) {
          this.$toast.success("提交成功");
          Object.assign(this.$data.params, this.$options.data().params);
        }
      } catch (error) {
        loading.clear();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.content {
  width: 80vw;
  margin: 0 auto;
  padding-top: 120px;

  .box {
    height: 50vh;
    background-color: #fff;
    text-align: center;
    color: #999;
    margin-bottom: 20px;
    line-height: 30px;
    overflow: hidden;
    position: relative;

    .photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;

    .btn {
      flex-grow: 1;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      text-align: center;
      letter-spacing: 5px;

      &:active {
        transform: scale(0.9);
      }
    }
    .change {
      line-height: 34px;
      background: #fff;
      color: #157c83;
      border: 3px solid;
      box-sizing: border-box;
      border-image: linear-gradient(-18deg, #0dfbf8, #64b5c8) 6 6;
      margin-right: 3%;
    }
    .submit {
      background: linear-gradient(#fff, #b0eef1);
      color: #388584;
    }
  }
  .custom-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ::v-deep {
      .van-empty__image {
        width: 40px;
        height: 40px;
      }
      .van-empty__description {
        white-space: nowrap;
      }
    }
  }
}
</style>
