import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// 引入vant
import "./plugins/vantInstall";

// 引入自定义样式
import "./scss/uiCover.scss";

// 绑定 api
import api from "./util/api";
Vue.prototype.$api = api;

Vue.config.productionTip = false;

// 引入转盘抽奖插件
import VueLuckyCanvas from "@lucky-canvas/vue";
Vue.use(VueLuckyCanvas);

// 判断是否在微信环境
Vue.prototype.$isWeixin = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger";
};
// 获取url参数
Vue.prototype.$getUrlParam = (key) => {
  let url = window.location.href;
  let startIndex = url.indexOf("?");
  let params = {};
  if (startIndex > -1) {
    let str = url.substring(startIndex + 1);
    let arr = str.split("&");
    for (let i = 0; i < arr.length; i++) {
      const ele = arr[i];
      let kv = ele.split("=");
      if (kv[1].indexOf("#") > -1) {
        kv[1] = kv[1].substring(0, kv[1].indexOf("#"));
      }
      params[kv[0]] = kv[1];
    }
  }
  return params[key];
};

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
