<!-- 主页 -->
<template>
  <div class="frame">
    <top @routerGo="$router.go(-2)"></top>
    <img src="../assets/home_bg.jpg" alt="" class="bg" />
    <div class="content">
      <div class="map-box">
        <div class="kuang kuang1" @click="routerTo('/about')"></div>
        <div class="kuang kuang2" @click="routerTo('/form')"></div>
        <div class="kuang kuang3" @click="routerTo('/photo')"></div>
        <div class="kuang kuang4" @click="routerTo('/type')"></div>
      </div>
      <div class="tip-box">
        <div class="tip-title">游戏说明</div>
        <div class="line"></div>
        <p>
          <img src="../assets/photo.png" alt="" class="icon" />
          <span>
            1.观众完成“了解大运”、“精彩合影”等游戏并填写资料，可获得「大运新能源好礼盲盒」一份。
          </span>
        </p>
        <p>
          <img src="../assets/gift.png" alt="" class="icon" />
          <span>
            2.「大运新能源好礼盲盒」有多种礼品，随机发放，先到先得，送完即止。
          </span>
        </p>
        <p>
          <img src="../assets/question.png" alt="" class="icon" />
          <span>3. 活动详情可咨询工作人员进行了解。</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import top from "../components/top.vue";
export default {
  components: { top },
  data() {
    return {
      appId: "wx6da86b8072154b28",
    };
  },
  created() {
    if (!this.$isWeixin()) return;
    if (sessionStorage.getItem("openId")) return; // 不是首次进入则无需进行以下操作
    const code = this.$getUrlParam("code");
    const state = this.$getUrlParam("state");
    /**
     * 首次进入静默获取code判断用户是否已注册
     *    若未注册则调用主动授权页面，获取用户信息
     *    若已注册则直接进入页面
     */
    const redirectUrl = encodeURIComponent(
      "https://h5.dayunxinnengyuan.com/homePage"
    );
    if (!code) {
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appId +
        "&redirect_uri=" +
        redirectUrl +
        "&response_type=code&scope=snsapi_base&state=base#wechat_redirect";
      return;
    }
    // 调用code获取token接口
    // state:base静默userinfo主动授权
    this.$api.wxLogin({ code, state }).then((res) => {
      if (!res.data.ifEist) {
        // 用户是首次进入，开始主动授权
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.appId +
          "&redirect_uri=" +
          redirectUrl +
          "&response_type=code&scope=snsapi_userinfo&state=userinfo#wechat_redirect";
        return;
      }
      sessionStorage.setItem("openId", res.data.openid);
      localStorage.setItem("dy_token", res.data.tokenStr);
    });
  },
  mounted() {},
  methods: {
    routerTo(path) {
      if (!sessionStorage.getItem("openId")) return; // 获得用户信息之前不做跳转操作
      this.$router.push(path);
    },
  },
};
</script>

<style lang='scss' scoped>
.frame {
  min-height: 100vh;
  position: relative;

  .bg {
    width: 100%;
    height: auto;
  }

  .content {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;

    .hello {
      margin-bottom: 20px;
      margin-top: 40px;
      letter-spacing: 1px;
    }
    .title {
      font-size: 44px;
      line-height: 55px;
      letter-spacing: 5px;
      font-weight: bolder;

      span {
        color: #2f7573;
      }
    }
    .task {
      font-weight: 700;
      margin-top: 37px;
      letter-spacing: 1px;
    }
    .under {
      width: 100%;
      height: auto;
    }
    .map-box {
      .kuang {
        width: 27vw;
        height: 27vw;
        background-color: #000;
        border: 1px solid #fff;
        position: absolute;
        opacity: 0;
      }
      .kuang1 {
        left: 22%;
        top: 32%;
      }
      .kuang2 {
        left: 22%;
        top: 41%;
      }
      .kuang3 {
        right: 21%;
        top: 41%;
      }
      .kuang4 {
        width: 30vw;
        height: 30vw;
        right: 14%;
        top: 30%;
        transform: rotate(8deg);
      }
    }
    .map {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }
    .tip-box {
      width: 70%;
      text-align: left;
      line-height: 32px;
      padding: 40px 15%;
      color: #fff;
      background: url("../assets/kuang.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      top: 60%;
      transform: translateX(-50%);

      .tip-title {
        font-weight: 700;
        font-size: 20px;
        padding-left: 36px;
      }
      .line {
        background-color: #fff;
        width: 100%;
        height: 1px;
        margin: 16px auto;
      }
      p {
        display: flex;
        line-height: 32px;
        margin-bottom: 1vw;

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
