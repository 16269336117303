import Vue from "vue";
import Router from "vue-router";

import guide from "../views/guide";
import welcome from "../views/welcome";
import homePage from "../views/homePage";
import about from "../views/about";
import busType from "../views/type";
import form from "../views/form";
import photo from "../views/photo";
import verification from "../views/verification";
import notFound from "../views/error/404";

import Layout from "../layout";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Layout,
      children: [
        {
          path: "/",
          name: "首页",
          component: guide,
        },
        {
          path: "/welcome",
          name: "欢迎页",
          component: welcome,
        },
        {
          path: "/homePage",
          name: "主页",
          component: homePage,
          meta: {
            isWeixin: true,
          },
        },
        {
          path: "/about",
          name: "关于大运",
          component: about,
        },
        {
          path: "/type",
          name: "了解车型",
          component: busType,
        },
        {
          path: "/form",
          name: "好礼盲盒",
          component: form,
          meta: {
            isWeixin: false,
            auth: false,
          },
        },
        {
          path: "/photo",
          name: "精彩合影",
          component: photo,
          meta: {
            isWeixin: true,
            auth: true,
          },
        },
        {
          path: "/verification",
          name: "核销页面",
          component: verification,
          meta: {
            isWeixin: true,
          },
        },
        {
          path: "/notFound",
          name: "404",
          component: notFound,
        },
      ],
    },
    {
      path: "/notWeixin",
      name: "notWeixin",
      component: () => import("../views/notWeixin"),
    },
    // { path: "*", redirect: "/notFound", hidden: true },
  ],
});

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0 });
  if (to.meta.isWeixin) {
    // 需要微信授权的页面
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.match(/MicroMessenger/i) == "micromessenger";

    if (!isWeixin) {
      // 非微信进入
      next("/notWeixin");
      return;
    }
    const token = localStorage.getItem("dy_token");
    if (!token && to.meta.auth) {
      // 无登录进入
      next("/welcome");
      return;
    }
    next();
  }
  next();
});

export default router;
