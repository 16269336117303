import Vue from "vue";

import {
  Toast,
  Field,
  Button,
  Popup,
  Picker,
  Empty,
  Dialog,
  Notify,
} from "vant";

Vue.use(Empty);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Dialog);
Vue.use(Notify);
